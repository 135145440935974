import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Badge from "../Badge/Badge"

const query = graphql`
  query FooterQuery {
    strapi {
      navigationItems {
        data {
          attributes {
            display_name
            url
            special
            order
          }
        }
      }
    }
  }
`

type NavigationItem = {
  attributes: {
    display_name: string
    url: string
    special: boolean
    order: number
  }
}

const Wrapper = (props: any) => {
  const navigationItems = props.strapi.data
    .filter((x: NavigationItem) => !x.attributes.special)
    .sort(
      (a: NavigationItem, b: NavigationItem) =>
        a.attributes.order - b.attributes.order
    )
  return (
    <div
      className="footer d-print-none pt-3 pb-5"
      style={{ background: "#009999" }}
    >
      <div className="container">
        <div className="d-flex flex-row flex-wrap p-3">
          <div className="col-12">
            <div className="d-flex justify-content-between flex-wrap">
              <StaticImage
                src="../../assets/images/realise_and_logo.png"
                alt="Full Logo"
                style={{ width: "186px", height: "90px" }}
                backgroundColor="transparent"
                objectFit="contain"
                loading="lazy"
                className="mb-2"
              />
              <div
                className="mx-0 mb-3"
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                title="Une page web fait en moyenne 1Mo. Cette page est 3 fois plus légère que la moyenne. L'estimation de C02 est réalisée d'après le site https://www.websitecarbon.com"
              >
                <Badge />
              </div>
            </div>
              <span className="text-white" style={{ fontSize: "0.8em", lineHeight: "1.15em", marginBottom: '1rem', display: 'block' }}>
                SIRET : 92266630000012 / APE 7022Z
                <br />
                Prestataire de formation enregistré sous le numéro 11756659975. 
                <br />
                Cet enregistrement ne vaut pas agrément de l’Etat.
              </span>
            <div
              className="mb-4"
              style={{ height: "1px", background: "#dddddd" }}
            ></div>
            <div className="d-flex flex-row align-content-start flex-wrap">
              <div className="d-flex flex-column">
                {navigationItems
                  .slice(0, navigationItems.length / 2 + 1)
                  .map((item: NavigationItem, index: number) => (
                    <Link
                      key={index}
                      className={`nav-link nav-item text-start ms-0 me-4 ${
                        item.attributes.special
                          ? "button-special"
                          : "button-normal"
                      }`}
                      to={item.attributes.url}
                      aria-label={item.attributes.display_name}
                    >
                      {item.attributes.display_name}
                    </Link>
                  ))}
              </div>
              <div className="d-flex flex-column">
                {navigationItems
                  .slice(
                    navigationItems.length / 2 + 1,
                    navigationItems.length + 1
                  )
                  .map((item: NavigationItem, index: number) => (
                    <Link
                      key={index}
                      className={`nav-link nav-item text-start ms-0 me-4 ${
                        item.attributes.special
                          ? "button-special"
                          : "button-normal"
                      }`}
                      to={item.attributes.url}
                      aria-label={item.attributes.display_name}
                    >
                      {item.attributes.display_name}
                    </Link>
                  ))}
                <Link
                  className="nav-link nav-item text-start button-normal ms-0 me-4"
                  to="/mentions-legales"
                  aria-label="Mentions légales"
                >
                  MENTIONS LÉGALES
                </Link>
              </div>
              <div className="d-flex flex-fill flex-column justify-content-start justify-content-md-end align-items-md-end align-items-start mt-3 mt-md-1">
                <span className="text-white" style={{ fontSize: "16px", lineHeight: "10px" }}>
                  © Kiwi, Inc. 2022. We love our users!
                </span>
                <div className="d-flex flex-column mt-3 text-start" style={{fontSize: "10px", lineHeight: "10px"}}>
                  <span className="text-white" style={{ fontSize: "10px", lineHeight: "10px" }}>
                    Icon made by Smashingstocks from www.flaticon.com
                  </span>
                  <span className="text-white" style={{ fontSize: "10px", lineHeight: "10px" }}>
                    Icon made by Prettycons from www.flaticon.com
                  </span>
                  <span className="text-white" style={{ fontSize: "10px", lineHeight: "10px" }}>
                    Icon made by Freepik from www.flaticon.com
                  </span>
                  <span className="text-white" style={{ fontSize: "10px", lineHeight: "10px" }}>
                    Icon made by Gregor Cresnar from www.flaticon.com
                  </span>
                  <span className="text-white" style={{ fontSize: "10px", lineHeight: "10px" }}>
                    Icon made by Winnievizence from www.flaticon.com
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Footer(props: any) {
  return (
    <StaticQuery
      query={query}
      render={data => <Wrapper strapi={data.strapi.navigationItems} />}
    />
  )
}

export default Footer
