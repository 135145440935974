import * as React from "react"
import Helper from "../Helper/Helper"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import "./MarkdownBlocks.scss"

type MarkdownBlocksProps = {
  items: {
    text: string
    text_color: string
    text_alignment: "left" | "center" | "right"
    background_color: string
    horizontal_padding: number
  }[]
  m_background_color: string
  rounded_corner: boolean
}

function flatten(text: string, child: any): any {
  return typeof child === "string"
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text)
}

function ListItem(props: any, color: string) {
  var children = React.Children.toArray(props.children)
  var text = children.reduce(flatten, "")
  return React.createElement(
    "li",
    { className: "markdown-list" },
    React.createElement(
      "div",
      {
        className: "d-inline-block",
        style: {
          background: color,
          display: "inline-block",
          width: "0.5em",
          height: "0.5em",
          marginLeft: "-1em",
          marginRight: "0.75em",
          borderRadius: "100%",
        },
      },
      ""
    ),
    React.createElement("p", { className: "d-inline" }, text)
  )
}

const MarkdownBlocks = (props: any) => {
  const section: MarkdownBlocksProps = props.section

  const items = section.items
  const roundedCorner = section.rounded_corner
  const backgroundColor = section.m_background_color
    ? Helper.color(section.m_background_color)
    : "#ffffff"

  return (
    <div
      className="w-100 background markdown-blocks "
      style={{ background: backgroundColor }}
    >
      <div
        className="container mx-auto"
        style={{ background: backgroundColor }}
      >
        <div className="row">
          <div className="container mx-0 my-3">
            <div
              className="d-flex flex-row flex-wrap overflow-hidden"
              style={{ borderRadius: roundedCorner ? "100px 0 100px 0" : "" }}
            >
              {items.map((item, index: number) => (
                <div
                  key={index}
                  className={`col-12 col-md-6 col-lg-3 p-3 px-${
                    item.horizontal_padding ?? "5"
                  } d-flex`}
                  style={{
                    color: Helper.color(item.text_color),
                    background: Helper.color(item.background_color),
                    textAlign: item.text_alignment,
                  }}
                >
                  <div className="d-block m-auto">
                    <Markdown
                      children={item.text}
                      remarkPlugins={[remarkGfm]}
                      components={{
                        li: ({ node, ...props }) =>
                          ListItem(props, item.text_color),
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarkdownBlocks
