import * as React from "react"
import Helper from "../Helper/Helper"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import "./References.scss"

type ReferencesProps = {
  items: {
    title: string
    description: string
    quote: string
    reference: string
  }[]
  ref_background_color: string
  ref_description_text_color: string
  ref_title_text_color: string
}

const References = (props: any) => {
  const section: ReferencesProps = props.section

  const items = section.items
  const background = section.ref_background_color
    ? Helper.color(section.ref_background_color)
    : "#ffffff"
  const title_text_color = section.ref_title_text_color
    ? Helper.color(section.ref_title_text_color)
    : "#ffffff"
  const description_text_color = section.ref_description_text_color
    ? Helper.color(section.ref_description_text_color)
    : "#ffffff"

  return (
    <div
      className="w-100 mx-auto background"
      style={{ background: background }}
    >
      <div className="container my-3">
        <div className="d-md-flex d-none flex-row flex-wrap justify-content-around">
          {items.map((item: any, index: number) => (
            <React.Fragment key={index}>
              <div
                key={`${index}_1`}
                className="col-sm-6 col-12 my-3 px-3"
                style={{ borderLeft: "6px solid #009999" }}
              >
                <h4
                  style={{
                    textAlign: "left",
                    color: title_text_color,
                  }}
                >
                  {item.title}
                </h4>
                <div
                  style={{
                    textAlign: "left",
                    color: description_text_color,
                  }}
                >
                  <Markdown
                    children={item.description}
                    remarkPlugins={[remarkGfm]}
                  />
                </div>
              </div>
              <div
                key={`${index}_2`}
                className="col-sm-6 col-12 my-3 px-3"
                style={{
                  textAlign: "left",
                  color: description_text_color,
                }}
              >
                <div className="d-block" style={{ height: "48px" }} />
                <Markdown
                  className="mx-3 pt-2"
                  children={item.quote}
                  remarkPlugins={[remarkGfm]}
                />
                <p
                  className="text-center"
                  style={{
                    fontSize: "16px",
                    fontStyle: "italic",
                  }}
                >
                  {item.reference}
                </p>
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="d-md-none d-flex flex-row flex-wrap">
          <div className="col-12 d-flex flex-column">
            {items.map((item: any, index: number) => (
              <div
                key={`${index}_3`}
                className="my-3 px-3"
                style={{ borderLeft: "6px solid #009999" }}
              >
                <h4
                  style={{
                    fontSize: "24px",
                    fontWeight: "700",
                    lineHeight: "48px",
                    letterSpacing: "-0.015em",
                    textAlign: "left",
                    color: title_text_color,
                  }}
                >
                  {item.title}
                </h4>
                <div
                  style={{
                    textAlign: "left",
                    color: description_text_color,
                  }}
                >
                  <Markdown
                    children={item.description}
                    remarkPlugins={[remarkGfm]}
                  />
                </div>
              </div>
            ))}
          </div>
          <div
            className="w-25 d-block m-auto my-3"
            style={{ height: "3px", background: "#dddddd" }}
          />
          <div className="col-12 d-flex flex-column">
            {items.map((item: any, index: number) => (
              <div key={`${index}_4`} className="d-flex flex-column">
                <div
                  key={index}
                  className="my-3 px-3"
                  style={{
                    textAlign: "left",
                    color: description_text_color,
                  }}
                >
                  <Markdown
                    className="mx-3 pt-3"
                    children={item.quote}
                    remarkPlugins={[remarkGfm]}
                  />
                  <p
                    className="text-center"
                    style={{
                      fontSize: "16px",
                      fontStyle: "italic",
                    }}
                  >
                    {item.reference}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default References
