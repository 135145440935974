function image(image: any, type: any) {
  if (image === undefined) {
    return null
  }

  switch (type) {
    case "small":
      return image.formats?.small?.url ?? image.url
    case "medium":
      return (
        image.formats?.medium?.url ?? image.formats?.small?.url ?? image.url
      )
    case "large":
      return (
        image.formats?.large?.url ?? image.formats?.medium?.url ?? image.url
      )
    case "xlarge":
      return (
        image.formats?.xlarge?.url ?? image.formats?.large?.url ?? image.url
      )
    default:
      return image.url
  }
}

function color(color: string) {
  if (color === undefined) {
    return "#000"
  }

  switch (color) {
    case "primary":
      return "#009999"
    case "secondary":
      return "#CCEBEB"
    case "special":
      return "#F2C12E"
    case "dark":
      return "#022873"
    case "light":
      return "#EAF9F9"
    case "intermediate":
      return "#4AB7B7"
    case "white":
      return "#fff"
    case "black":
      return "#000"
    default:
      return "#000"
  }
}

const Helper = {
  image,
  color,
}

export default Helper
