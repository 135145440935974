import * as React from "react"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import Helper from "../Helper/Helper"
import "./Paragraphs.scss"

type ParagraphsProps = {
  items: {
    title: string
    description: string
  }[]
  p_background_color: string
  p_title_text_color: string
  p_description_text_color: string
}

const Paragraphs = (props: any) => {
  const section: ParagraphsProps = props.section

  const items = section.items
  const background = section.p_background_color
    ? Helper.color(section.p_background_color)
    : "#ffffff"
  const title_text_color = section.p_title_text_color
    ? Helper.color(section.p_title_text_color)
    : "#000000"
  const description_text_color = section.p_description_text_color
    ? Helper.color(section.p_description_text_color)
    : "#000000"

  return (
    <div
      className="w-100 mx-auto background"
      style={{ background: background }}
    >
      <div className="container my-3">
        <div className="d-flex justify-content-around flex-wrap paragraphs">
          {items.map((item: any, index: number) => (
            <div key={index} className="col-12 col-lg-6 col-xl-3  p-3">
              <div className="w-100">
                <div
                  className="paragraph"
                  style={{ borderTop: "6px solid #009999" }}
                ></div>
                <h4
                  style={{
                    fontWeight: "700",
                    textAlign: "left",
                    color: title_text_color,
                  }}
                >
                  {item.title}
                </h4>
                <div
                  style={{
                    textAlign: "left",
                    color: description_text_color,
                  }}
                >
                  <Markdown
                    children={item.description}
                    remarkPlugins={[remarkGfm]}
                    components={{}}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Paragraphs
