import * as React from "react"
import Helper from "../Helper/Helper"
import "./Bubbles.scss"
import Bubble from "../../assets/images/Bubble.png"
import { GatsbyImage } from "gatsby-plugin-image"

type BubblesProps = {
  items: {
    bubble: {
      data: {
        attributes: {
          url: string
          alternativeText: string
          imageFile: any
        }
      }
    }
    text: string
  }[]
  b_background_color: string
  b_text_color: string
}

interface Bubble {
  url: string
  text: string
}

const BubbleImage = (props: any) => {
  const image = props.imageFile?.childImageSharp?.fluid
  const alt = props.alt
  return (
    <div className="mx-auto h-100" style={{ width: "95%" }}>
      <img 
        src={image.srcWebp} 
        loading="lazy" 
        alt={alt}
        className="h-100 p-4"
        style={{objectFit: "contain", width: "100%", margin: "auto"}}/>
    </div>
  )
}

const Bubbles = (props: any) => {
  const section: BubblesProps = props.section
  const pairs = section.items
  const background = section.b_background_color
    ? Helper.color(section.b_background_color)
    : "#ffffff"
  const text_color = section.b_text_color
    ? Helper.color(section.b_text_color)
    : "#000000"

  return (
    <div
      className="w-100 mx-auto pb-5 background"
      style={{ background: background }}
    >
      <div className="container my-3">
        <div className="d-flex justify-content-around flex-wrap flex-md-nowrap bubbles">
          {pairs.map((pair: any, index: number) => (
            <React.Fragment key={index}>
              <div className="d-flex flex-column m-lg-0 m-2">
                <div
                  className="mx-auto text-center"
                  title="Bubble"
                  style={{
                    width: "250px",
                    height: "300px",
                    backgroundImage: `url( '${Bubble}' )`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <BubbleImage
                    imageFile={pair.bubble?.data?.attributes.imageFile}
                    alt={pair.bubble?.data?.attributes?.alternativeText}
                  />
                </div>
                <h4
                  className="d-block mt-4 mx-auto"
                  style={{
                    color: text_color,
                    fontSize: "24px",
                    fontWeight: "500",
                    lineHeight: "48px",
                    letterSpacing: "-0.015em",
                    textAlign: "center",
                  }}
                >
                  {pair.text}
                </h4>
              </div>
              <svg
                className="m-0 d-md-block d-none"
                viewBox="0 0 100 3"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  width: "100%",
                  height: "300px",
                }}
              >
                <polygon points="0,0 100,0 100,3 0,3" fill="white" />
              </svg>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Bubbles
