import React from "react"
import Footer from "../Footer/Footer"
import Header from "../Header/Header"
import "./Layout.scss"

interface LayoutProps {
  children?: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <main>
      <div className="body">
        <div id="wrapper">
          <Header />
          {children}
        </div>
      </div>
      <Footer />
    </main>
  )
}

export default Layout
