import * as React from "react"
import Helper from "../Helper/Helper"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import "./Hook.scss"

type HookProps = {
  title: string
  description: string
  buttons: {
    text: string,
    href: string,
    h_button_background_color: string,
    h_button_text_color: string
  }[]
  h_background_color: string
  h_title_text_color: string
  h_description_text_color: string
}

const Hook = (props: any) => {
  const section: HookProps = props.section

  const title = section.title
  const description = section.description
  const buttons = section.buttons
  const background = section.h_background_color
    ? Helper.color(section.h_background_color)
    : "#ffffff"
  const title_text_color = section.h_title_text_color
    ? Helper.color(section.h_title_text_color)
    : "#000000"
  const subtitle_text_color = section.h_description_text_color
    ? Helper.color(section.h_description_text_color)
    : "#000000"

  return (
    <div
      className="w-100 pt-3 mx-auto background"
      style={{ background: background }}
    >
      <div className="container my-3">
        <div className="row d-flex flex-row flex-wrap align-items-start px-2">
          <div className="col-md-5 col-12 px-3">
            <h3
              className="d-inline-block position-relative text-start mb-3"
              style={{
                color: title_text_color,
                fontWeight: "700",
                fontSize: "30px",
                lineHeight: "48px",
              }}
            >
              {title}
            </h3>
          </div>
          <div
            className="col-md-7 col-12 px-3"
            style={{ color: subtitle_text_color }}
          >
            <Markdown children={description} remarkPlugins={[remarkGfm]} />
            <div className="d-flex flex-row flex-wrap justify-content-start mt-5 mb-3">
              {buttons.map((item, index: number) => (
                <a key={index}
                  className="button-contact hover-underline-animation me-4" 
                  href={item.href} 
                  style={{
                    backgroundColor: Helper.color(item.h_button_background_color), 
                    color: Helper.color(item.h_button_text_color),
                  }}>
                  {item.text}
                </a>
              ))}
            </div>
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default Hook
