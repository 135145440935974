import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"
import Helper from "../Helper/Helper"
import "./Steps.scss"

type StepsProps = {
  title: string
  items: {
    icon: {
      data: {
        attributes: {
          url: string
          alternativeText: string
          imageFile: any
        }
      }
    }
  }[]
  s_background_color: string
  s_title_text_color: string
  s_items_background_color: string
}

const Steps = (props: any) => {
  const section: StepsProps = props.section

  const items = section.items
  const title = section.title
  const background = section.s_background_color
    ? Helper.color(section.s_background_color)
    : "#ffffff"
  const title_text_color = section.s_title_text_color
    ? Helper.color(section.s_title_text_color)
    : "#000000"
  const s_items_background_color = section.s_items_background_color
    ? Helper.color(section.s_items_background_color)
    : "#ffffff"

  return (
    <div
      className="w-100 mx-auto py-3 background"
      style={{ background: background }}
    >
      <div className="container my-3">
        <h3
          className="mb-5"
          style={{
            textAlign: "center",
            color: title_text_color,
          }}
        >
          {title}
        </h3>
        <div
          className="
                    d-flex 
                    flex-column 
                    flex-md-row 
                    justify-content-around 
                    align-items-center 
                    flex-wrap 
                    flex-md-nowrap 
                    arrows"
        >
          {items.map((item: any, index: number) => (
            <React.Fragment key={index}>
              <div
                className="round-container overflow-hidden mt-3"
                style={{
                  borderRadius: "100%",
                  background: s_items_background_color,
                  textAlign: "center",
                }}
              >
                <div
                  className="mx-auto h-100 overflow-hidden"
                >
                  <img 
                    src={item.icon?.data?.attributes?.imageFile.childImageSharp?.fluid.srcWebp} 
                    loading="lazy" 
                    alt={item.icon?.data?.attributes?.alternativeText}
                    className="h-100"
                    style={{objectFit: "contain", width: "100%", margin: "auto", borderRadius: "50px", backgroundColor: "transparent"}}/>
                </div>
              </div>
              {/* <svg
                className="mx-4 my-md-0 my-4"
                style={{
                  width: "60px",
                  height: "50px",
                }}
                viewBox="0 0 105 74"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 32C2.23858 32 0 34.2386 0 37C0 39.7614 2.23858 42 5 42V32ZM103.536 40.5355C105.488 38.5829 105.488 35.4171 103.536 33.4645L71.7157 1.64466C69.7631 -0.307961 66.5973 -0.307961 64.6447 1.64466C62.692 3.59728 62.692 6.76311 64.6447 8.71573L92.9289 37L64.6447 65.2843C62.692 67.2369 62.692 70.4027 64.6447 72.3553C66.5973 74.308 69.7631 74.308 71.7157 72.3553L103.536 40.5355ZM5 42H100V32H5V42Z"
                  fill="#009999"
                />
              </svg> */}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Steps
