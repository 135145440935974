import * as React from "react"
import Helper from "../Helper/Helper"
import "./Columns.scss"

type ColumnsProps = {
  columns: {
    items: {
      title: string
      subtitle: string
      title_text_color: string
      subtitle_text_color: string
    }
  }[]
  c_background_color: string
  c_text_color: string
}

interface Column {
  items: {
    title: string
    subtitle: string
    title_text_color: string
    subtitle_text_color: string
  }[]
}

const Column = (props: any) => {
  const column: Column = props.column
  const width: number = props.width
  return (
    <>
      <div className={`d-flex flex-column col-12 col-lg-${width}`}>
        {column.items.map((item, index: number) => (
          <div key={index}>
            <h4
              className="d-block mt-4 mx-auto mb-4"
              style={{
                color: Helper.color(item.title_text_color),
                textAlign: "left",
                fontWeight: "bold" 
              }}
            >
              {item.title}
            </h4>
            <p
              style={{
                color: Helper.color(item.subtitle_text_color),
                textAlign: "left",
                whiteSpace: "normal",
              }}
            >
              {item.subtitle}
            </p>
          </div>
        ))}
      </div>
      <svg
        className="d-lg-none d-block horizontal-bar px-3 my-3 col-1"
        viewBox="0 0 200 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: "60%",
          minHeight: "10px",
        }}
      >
        <polygon points="0,0 200,0 200,1 0,1" fill="#009999" />
      </svg>
      <svg
        className="d-lg-block d-none vertical-bar col-1"
        viewBox="0 0 10 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: "10px",
          minHeight: "100%",
        }}
      >
        <polygon points="3,0 6,0 6,200 3,200" fill="#009999" />
      </svg>
    </>
  )
}

const Columns = (props: any) => {
  const section: ColumnsProps = props.section

  const columns = section.columns
  const background = section.c_background_color
    ? Helper.color(section.c_background_color)
    : "#ffffff"

  const width = columns.length % 4 == 0 ? 2 : 3

  return (
    <div
      className="w-100 mx-auto pb-3 background"
      style={{ background: background }}
    >
      <div className="container my-3">
        <div className="d-flex justify-content-around flex-wrap columns align-content-start">
          {columns.map((column: any, index: number) => (
            <Column key={index} column={column} width={width} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Columns
