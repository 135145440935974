import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { WebsiteCarbonBadge } from "react-websitecarbon-badge"
import "./Badge.scss"

const query = graphql`
  query WebsiteCarbonQuery {
    websiteCarbonBadge {
      url
      originalUrl
      green
      bytes
      cleanerThan
      statistics {
        adjustedBytes
        energy
        co2 {
          grid {
            grams
            litres
          }
          renewable {
            grams
            litres
          }
        }
      }
      timestamp
    }
  }
`;

function Wrapper(props: any) {
  console.log(props)
  const wc = props.data.websiteCarbonBadge
  const url = wc?.url
  const co2 = wc?.statistics.co2.renewable.grams.toFixed(2)
  const percentage = (wc?.cleanerThan * 100).toString()
  
  return (
  <>
  <WebsiteCarbonBadge
    dark={true} 
    url={url}
    co2={co2} 
    percentage={percentage} 
    lang="fr"
  /> 
  <p className="text-start text-md-end text-white mb-2" style={{ fontSize: "0.9em", lineHeight: "1.15" }}><b>Site web éco conçu</b> ~ 300 Ko de données</p>
  </>)
}

function Badge() {
  return (
    <StaticQuery
      query={query}
      render={data => 
        <Wrapper data={data}/>
      }
    />
  )
}

export default Badge
