import * as React from "react"
import Helper from "../Helper/Helper"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import "./Offers.scss"

type OffersProps = {
  items: {
    title: string
    description: string
  }[]
  o_background_color: string
  o_description_text_color: string
  o_title_text_color: string
}

function flatten(text: string, child: any): any {
  return typeof child === "string"
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text)
}

function Heading(props: any, color: string) {
  var children = React.Children.toArray(props.children)
  var text = children.reduce(flatten, "")
  return React.createElement(
    "h" + props.level,
    { style: { color: color } },
    text
  )
}

function ListItem(props: any, color: string) {
  var children = React.Children.toArray(props.children)
  var text = children.reduce(flatten, "")
  return React.createElement(
    "li",
    { className: "markdown-list" },
    React.createElement(
      "div",
      {
        className: "d-inline-block",
        style: {
          background: color,
          display: "inline-block",
          width: "0.5em",
          height: "0.5em",
          marginLeft: "-1em",
          marginRight: "0.5em",
          borderRadius: "100%",
        },
      },
      ""
    ),
    React.createElement("span", {}, text)
  )
}

const Offers = (props: any) => {
  const section: OffersProps = props.section

  const items = section.items
  const background = section.o_background_color
    ? Helper.color(section.o_background_color)
    : "#ffffff"
  const title_text_color = section.o_title_text_color
    ? Helper.color(section.o_title_text_color)
    : "#ffffff"
  const description_text_color = section.o_description_text_color
    ? Helper.color(section.o_description_text_color)
    : "#ffffff"

  return (
    <div
      className="w-100 mx-auto offers background"
      style={{ background: background }}
    >
      <div className="container my-3">
        <div className="d-flex flex-row flex-wrap justify-content-around">
          {items.map((item: any, index: number) => (
            <div key={index} className="col-lg-3 col-md-6 col-12 my-3 px-3">
              <h3
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "48px",
                  letterSpacing: "-0.015em",
                  textAlign: "left",
                  color: title_text_color,
                  borderTop: "6px solid #009999",
                }}
              >
                {item.title}
              </h3>
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  letterSpacing: "-0.015em",
                  textAlign: "left",
                  color: description_text_color,
                }}
              >
                <Markdown
                  children={item.description}
                  remarkPlugins={[remarkGfm]}
                  components={{
                    h4: ({ node, ...props }) =>
                      Heading(props, title_text_color),
                    li: ({ node, ...props }) =>
                      ListItem(props, title_text_color),
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Offers
