import * as React from "react"
import { Helmet } from "react-helmet"
import Logo from "../../assets/favicons/favicon.png"

type MetadataProps = {
  metadata: {
    title: string
    description: string
    image: string
  }
}

function Seo(props: MetadataProps) {
  let title = props.metadata?.title
  let description = props.metadata?.description

  let image = props.metadata?.image

  if (image === undefined) {
    image = Logo
  }

  if (title === undefined) {
    title = ""
  }

  if (description === undefined) {
    description = ""
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: "fr",
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "Realise, Chloe de Fursac, Sckanda Ipour, Clément Joye",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:image`,
          content: image,
        },
        {
          name: "robots",
          content: "index, follow",
        },
      ]}
    />
  )
}

export default Seo
