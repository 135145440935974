import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Logo from "../../assets/images/realise_blanc.png"
import "./Header.scss"

const query = graphql`
  query NavigationQuery {
    strapi {
      navigationItems {
        data {
          attributes {
            display_name
            url
            special
            order
          }
        }
      }
    }
  }
`

type NavigationItem = {
  attributes: {
    display_name: string
    url: string
    special: boolean
    order: number
  }
}

const NavigationItems = (props: any) => {
  const setExpanded: any = props.collapse
  const navigationItems: NavigationItem[] = props.navigationItems
  return (
    <>
      {navigationItems.map((item: NavigationItem, index: number) => (
        <Link
          key={index}
          className={`nav-link nav-item ${
            item.attributes.special ? "button-special" : "button-normal"
          }`}
          to={item.attributes.url}
          aria-label={item.attributes.display_name}
          onClick={() => setExpanded(false)}
        >
          {item.attributes.display_name}
        </Link>
      ))}
    </>
  )
}

const Wrapper = (props: any) => {
  const [expanded, setExpanded] = React.useState(false)
  const navigationItems = props.navigationItems.data.sort(
    (a: NavigationItem, b: NavigationItem) =>
      a.attributes.order - b.attributes.order
  )
  return (
    <Navbar
      className="navbar navbar-expand-lg navbar-light d-print-none"
      collapseOnSelect
      expand="lg"
      bg="primary"
      variant="dark"
      fixed="top"
      expanded={expanded}
    >
      <Container className="justify-content-between bg-primary">
        <Navbar.Brand>
          <Link
            className="navbar-brand text-decoration-none d-inline-block"
            to="/"
            onClick={() => setExpanded(false)}
          >
            <img
              className="nav-brand-logo"
              src={Logo}
              alt="Logo"
              style={{ height: "70px" }}
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav navbar-dark border-light"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <NavigationItems
              navigationItems={navigationItems}
              collapse={setExpanded}
            />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

const Header = () => {
  return (
    <StaticQuery
      query={query}
      render={data => <Wrapper navigationItems={data.strapi.navigationItems} />}
    />
  )
}

export default Header
