import * as React from "react"
import Helper from "../Helper/Helper"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import "./Presentation.scss"

type PresentationProps = {
  title: string
  description: string
  buttons: {
    text: string,
    href: string,
    pr_button_background_color: string,
    pr_button_text_color: string
  }[]
  image: {
    data: {
      attributes: {
        url: string
        alternativeText: string
        imageFile: any
      }
    }
  }
  pr_background_color: string
  pr_title_text_color: string
  pr_description_text_color: string
}

const Presentation = (props: any) => {
  const section: PresentationProps = props.section

  const title = section.title
  const description = section.description
  const buttons = section.buttons
  const image =
    section.image?.data?.attributes.imageFile.childImageSharp?.fluid
  const alt = section.image?.data?.attributes.alternativeText
  const backgroundColor = section.pr_background_color
    ? Helper.color(section.pr_background_color)
    : "#ffffff"
  const titleTextColor = section.pr_title_text_color
    ? Helper.color(section.pr_title_text_color)
    : "#000000"
  const descriptionTextColor = section.pr_description_text_color
    ? Helper.color(section.pr_description_text_color)
    : "#000000"

  return (
    <div
      className="presentation w-100 mx-auto background"
      style={{ background: backgroundColor }}
    >
      <div className="container py-3">
        <div className="row justify-content-start mx-auto">
          <div className="col-sm-3 col-10 p-3 pt-2">
            {image?.srcWebp ? (<img 
              src={image?.srcWebp} 
              loading="lazy" 
              alt={alt}
              className="w-100 px-sm-0 px-3 mb-3"
              style={{objectFit: "contain", borderRadius: "50px"}}/>)
              : (null)
            }
          </div>
          <div className="col-sm-9 col-12 px-3">
            <h3
              className="mx-3 position-relative text-start"
              style={{
                textAlign: "start",
                fontWeight: "400",
                fontSize: "30px",
                lineHeight: "48px",
                color: titleTextColor,
              }}
            >
              {title}
            </h3>
            <div style={{ color: descriptionTextColor }}>
              <Markdown
                className="mx-3"
                children={description}
                remarkPlugins={[remarkGfm]}
              />
            </div>
            <div className="d-flex flex-row flex-wrap justify-content-start mx-3 mt-4 mb-3">
              {buttons.map((item, index: number) => (
                <a key={index} 
                  className="button-contact me-4" 
                  href={item.href} 
                  style={{
                    backgroundColor: Helper.color(item.pr_button_background_color), 
                    color: Helper.color(item.pr_button_text_color),
                  }}>
                  {item.text}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Presentation
