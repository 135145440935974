import React from "react"
import CookieConsent from "react-cookie-consent"
import Layout from "../components/Layout/Layout"
import SectionGroup from "../components/SectionGroup/SectionGroup"
import Seo from "../components/Seo/Seo"

function groupArrayOfObjects(list: any, key: any) {
  return list.reduce(function (rv: any, x: any) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

const PageTemplate = (props: any) => {
  const name = props.pageContext.name
  const metadata = props.pageContext.metadata
  const sections = props.pageContext.sections

  const [background, setBackground] = React.useState("#ffffff")

  let sectionGroups = groupArrayOfObjects(sections, "animation_order")

  let sectionGroupsArray = []

  for (let key of Object.keys(sectionGroups)) {
    sectionGroupsArray.push(sectionGroups[key])
  }

  const eventhandler = (data: string) =>
    setTimeout(() => setBackground(data), 600)

  return (
    <>
      <Layout>
        <Seo metadata={metadata} />
        <div
          id="content"
          className="d-flex flex-column justify-content-between"
          style={{
            minHeight: "90%",
            marginTop: "96px",
            background: background,
          }}
        >
          <div className={`d-flex flex-column`}>
            {sectionGroupsArray?.map((sectionGroup: any, index: number) => (
              <SectionGroup
                key={index}
                index={index}
                sectionGroup={sectionGroup}
                backgroundChange={eventhandler}
              ></SectionGroup>
            ))}
          </div>
        </div>
      </Layout>
      <CookieConsent
        location="bottom"
        buttonText="OK!"
        cookieName="simple_cookie"
        containerClasses="d-print-none"
        style={{
          background: "#009999",
          zIndex: "1000",
        }}
        buttonClasses="button-special fw-bold border-0"
        expires={90}
      >
        <h5 style={{ fontSize: "16px" }} className="text-white fw-bold">
          Ce site n'utilise pas de cookie.
        </h5>
        <p style={{ fontSize: "13px" }} className="text-white">
          Aucun cookie n'est utilisé sur ce site, à part celui utilisé pour
          cette bannière. <br />
          Si cela change dans le futur, nous mettrons à jour cette bannière avec
          plus d'informations. 🍪
        </p>
      </CookieConsent>
    </>
  )
}

export default PageTemplate
