import * as React from "react"
import Helper from "../Helper/Helper"
import "./InBetween.scss"

type InBetweenProps = {
  icon: {
    data: {
      attributes: {
        url: string
        alternativeText: string
        imageFile: any
        imageSvg: any
      }
    }
  }
  background_shape: {
    data: {
      attributes: {
        url: string
        alternativeText: string
        imageFile: any
        imageSvg: any
      }
    }
  }
  ib_background_color: string
  rounded_background_color: string
}

interface WrapperProps {
  children?: React.ReactNode
  shape: string
  shapeAlt: string
  background: string
  rounded_background_color: string
}

const Wrapper = ({
  children,
  shape,
  shapeAlt,
  background,
  rounded_background_color,
}: WrapperProps) => {
  if (shape) {
    return (
      <div
        className="w-100 d-block background in-between "
        title={shapeAlt}
        style={{
          backgroundImage: `url("${shape}")`,
          backgroundPosition: "right top",
          backgroundSize: "100% 100%",
          backgroundColor: background,
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      >
        {children}
      </div>
    )
  } else if (rounded_background_color) {
    return (
      <div
        className="w-100 background in-between "
        style={{
          background: background,
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "-150px",
          zIndex: -1,
        }}
      >
        <div
          className="w-100 h-100 d-block"
          style={{
            backgroundColor: rounded_background_color,
            borderRadius: "0 0 200px 200px",
          }}
        >
          {children}
        </div>
      </div>
    )
  } else {
    return (
      <div
        className="in-between w-100 d-block background"
        style={{
          backgroundColor: background,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {children}
      </div>
    )
  }
}

const InBetween = (props: any) => {
  const section: InBetweenProps = props.section
  
    const icon = section.icon?.data?.attributes?.imageFile?.svg?.content
    const iconAlt = section.icon?.data?.attributes?.alternativeText
    const shape =
    section.background_shape?.data?.attributes?.imageFile?.svg?.content
  const shapeAlt = section.background_shape?.data?.attributes?.alternativeText
  const backgroundColor = section.ib_background_color
    ? Helper.color(section.ib_background_color)
    : "rgba(255,255,255,0.0)"
  const roundedBackgroundColor = section.rounded_background_color
    ? Helper.color(section.rounded_background_color)
    : ""

  if (icon) {
    return (
      <Wrapper
        shape={shape}
        shapeAlt={shapeAlt}
        background={backgroundColor}
        rounded_background_color={roundedBackgroundColor}
      >
        <div className="container">
          <div
            className={`in-between ${shape ? "mt-6" : ""}`}
            title={iconAlt}
            style={{
              backgroundImage: `url("${icon}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              backgroundPosition: "center",
              margin: "auto",
              width: "10%",
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, 0)",
            }}
          ></div>
        </div>
      </Wrapper>
    )
  } else {
    return (
      <Wrapper
        shape={shape}
        shapeAlt={shapeAlt}
        background={backgroundColor}
        rounded_background_color={roundedBackgroundColor}
      ></Wrapper>
    )
  }
}

export default InBetween
