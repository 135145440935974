import * as React from "react"
import Helper from "../Helper/Helper"
import "./Title.scss"

type TitleProps = {
  title: string
  background_shape: {
    data: {
      attributes: {
        url: string
        alternativeText: string
      }
    }
  }
  tit_background_color: string
  tit_title_text_color: string
  tit_text_alignment: "left" | "center" | "right"
}

interface WrapperProps {
  children?: React.ReactNode
  shape: string
  shapeAlt: string
  background: string
}

const Wrapper = ({ children, shape, shapeAlt, background }: WrapperProps) => {
  if (shape) {
    return (
      <div
        className="w-100 d-block pt-md-6 pt-3 background"
        style={{
          backgroundImage: `url( '${shape}' )`,
          backgroundPosition: "right top",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          backgroundColor: background,
          marginLeft: "auto",
          marginRight: "auto",
          height: "auto",
        }}
      >
        {children}
      </div>
    )
  } else {
    return (
      <div className="w-100 background" style={{ background: background }}>
        <div className="container container-fluid py-5">
          <div className="row">{children}</div>
        </div>
      </div>
    )
  }
}

const Title = (props: any) => {
  const section: TitleProps = props.section

  const title = section.title
  const shape = section.background_shape?.data?.attributes?.url
  const shapeAlt = section.background_shape?.data?.attributes?.alternativeText
  const background_color = section.tit_background_color
    ? Helper.color(section.tit_background_color)
    : "#ffffff"
  const title_text_color = section.tit_title_text_color
    ? Helper.color(section.tit_title_text_color)
    : "#000000"
  const text_alignment = section.tit_text_alignment
    ? section.tit_text_alignment
    : "center"

  return (
    <Wrapper shape={shape} shapeAlt={shapeAlt} background={background_color}>
      <div className="container my-3">
        <h2
          className="mb-5 mx-3"
          style={{
            textAlign: text_alignment,
            color: title_text_color,
          }}
        >
          {title}
        </h2>
      </div>
    </Wrapper>
  )
}

export default Title
