import * as React from "react"
import Helper from "../Helper/Helper"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import "./Text.scss"

type TextProps = {
  title: string
  subtitle: string
  t_background_color: string
  t_title_text_color: string
  t_subtitle_text_color: string
  t_text_alignment: "left" | "center" | "right"
}

function flatten(text: string, child: any): any {
  return typeof child === "string"
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text)
}

function Heading(props: any, color: string) {
  var children = React.Children.toArray(props.children)
  var text = children.reduce(flatten, "")
  return React.createElement(
    "h" + props.level,
    { style: { color: color, marginTop: "3rem", marginBottom: "2rem" } },
    text
  )
}

const Text = (props: any) => {
  const section: TextProps = props.section

  const title = section.title
  const subtitle = section.subtitle
  const background = section.t_background_color
    ? Helper.color(section.t_background_color)
    : "#ffffff"
  const title_text_color = section.t_title_text_color
    ? Helper.color(section.t_title_text_color)
    : "#000000"
  const subtitle_text_color = section.t_subtitle_text_color
    ? Helper.color(section.t_subtitle_text_color)
    : "#000000"
  const text_alignment = section.t_text_alignment
    ? section.t_text_alignment
    : "center"

  return (
    <div
      className="w-100 pt-3 mx-auto background"
      style={{ background: background }}
    >
      <div className="container my-3">
        <h3
          className="mt-5 mx-4 position-relative text-center"
          style={{
            textAlign: text_alignment,
            color: title_text_color,
          }}
        >
          {title}
        </h3>
        <div
          className="my-5"
          style={{
            textAlign: text_alignment,
            color: subtitle_text_color,
          }}
        >
          <Markdown
            className="mx-3"
            children={subtitle}
            remarkPlugins={[remarkGfm]}
            components={{
              h4: ({ node, ...props }) => Heading(props, title_text_color),
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Text
