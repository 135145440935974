import * as React from "react"
import Helper from "../Helper/Helper"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import "./Rectangles.scss"

type RectanglesProps = {
  title: string
  items: {
    bubble: {
      data: {
        attributes: {
          url: string
        }
      }
    }
    text: string
  }[]
  r_background_color: string
  r_title_text_color: string
  r_description_text_color: string
  r_items_background_color: string
}

const Rectangles = (props: any) => {
  const section: RectanglesProps = props.section

  const title = section.title
  const items = section.items
  const background = section.r_background_color
    ? Helper.color(section.r_background_color)
    : "#ffffff"
  const title_text_color = section.r_title_text_color
    ? Helper.color(section.r_title_text_color)
    : "#000000"
  const description_text_color = section.r_description_text_color
    ? Helper.color(section.r_description_text_color)
    : "#000000"
  const items_background_color = section.r_items_background_color
    ? Helper.color(section.r_items_background_color)
    : "#ffffff"

  return (
    <div
      className="w-100 mx-auto background"
      style={{ background: background }}
    >
      <div className="container my-3">
        <h3
          className="mb-5"
          style={{
            textAlign: "center",
            color: title_text_color,
          }}
        >
          {title}
        </h3>
        <div className="d-flex flex-column justify-content-around flex-wrap flex-md-nowrap rectangles">
          {items.map((item: any, index: number) => (
            <React.Fragment key={index}>
              <div className="d-flex flex-md-row flex-column align-items-center m-lg-0 m-2">
                <div className="d-block my-auto me-md-3 col-md-3 col-12">
                  <h4
                    className="mx-auto mb-3 mb-md-0"
                    style={{
                      color: title_text_color,
                      textAlign: "center",
                      hyphens: "auto",
                    }}
                  >
                    {item.title}
                  </h4>
                </div>
                <div
                  className="d-md-inline-block d-none my-auto bg-primary flex-grow-1"
                  style={{ width: "100px", height: "6px" }}
                ></div>
                <div
                  className="d-md-none d-inline-block my-auto bg-primary flex-grow-1"
                  style={{ width: "6px", height: "100px" }}
                ></div>
                <div
                  className="my-md-3 my-0 d-flex justify-content-center p-3 mb-3 mb-md-0"
                  style={{
                    border: `6px solid ${title_text_color}`,
                    borderRadius: "50px",
                    width: "100%",
                    maxWidth: "800px",
                    height: "auto",
                    minHeight: "200px",
                    background: items_background_color,
                  }}
                >
                  <div
                    className="m-auto p-3"
                    style={{
                      textAlign: item.description_text_alignment,
                      color: description_text_color,
                    }}
                  >
                    <Markdown
                      children={item.description}
                      remarkPlugins={[remarkGfm]}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Rectangles
