import * as React from "react"
import Helper from "../Helper/Helper"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import "./Subtitle.scss"

type SubtitleProps = {
  subtitle: string
  background_shape: {
    data: {
      attributes: {
        url: string
        alternativeText: string
      }
    }
  }
  sub_background_color: string
  sub_title_text_color: string
}

interface WrapperProps {
  children?: React.ReactNode
  shape: string
  shapeAlt: string
  background: string
}

const Wrapper = ({ children, shape, shapeAlt, background }: WrapperProps) => {
  return shape ? (
    <div
      className="w-100 d-block pt-md-6 pt-3 background"
      title={shapeAlt}
      style={{
        backgroundImage: `url( '${shape}' )`,
        backgroundPosition: "right top",
        backgroundSize: "100% 100%",
        backgroundColor: background,
        backgroundRepeat: "no-repeat",
        marginLeft: "auto",
        marginRight: "auto",
        height: "200px",
      }}
    >
      {children}
    </div>
  ) : (
    <div className="w-100 background" style={{ background: background }}>
      <div className="container py-5">
        <div className="row justify-content-center mx-auto">{children}</div>
      </div>
    </div>
  )
}

const Subtitle = (props: any) => {
  const section: SubtitleProps = props.section

  const title = section.subtitle
  const shape = section.background_shape?.data?.attributes?.url
  const shapeAlt = section.background_shape?.data?.attributes?.alternativeText
  const background_color = section.sub_background_color
    ? Helper.color(section.sub_background_color)
    : "#ffffff"
  const title_text_color = section.sub_title_text_color
    ? Helper.color(section.sub_title_text_color)
    : "#000000"

  return (
    <Wrapper shape={shape} shapeAlt={shapeAlt} background={background_color}>
      <div
        className="mx-5 subtitle-section"
        style={{
          fontSize: "24px",
          letterSpacing: "-0.015em",
          textAlign: "center",
          color: title_text_color,
        }}
      >
        <Markdown
          className="mx-3"
          children={title}
          remarkPlugins={[remarkGfm]}
        />
      </div>
    </Wrapper>
  )
}

export default Subtitle
