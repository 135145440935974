import * as React from "react"
import { useInView } from "react-intersection-observer"
import InBetween from "../InBetween/InBetween"
import TextImpact from "../TextImpact/TextImpact"
import Text from "../Text/Text"
import Bubbles from "../Bubbles/Bubbles"
import Columns from "../Columns/Columns"
import Rectangles from "../Rectangles/Rectangles"
import Steps from "../Steps/Steps"
import Paragraphs from "../Paragraphs/Paragraphs"
import Title from "../Title/Title"
import Subtitle from "../Subtitle/Subtitle"
import References from "../References/References"
import Presentation from "../Presentation/Presentation"
import Hook from "../Hook/Hook"
import Offers from "../Offers/Offers"
import MarkdownBlocks from "../MarkdownBlocks/MarkdownBlocks"
import Banner from "../Banner/Banner"

const SwitchSection = (props: any) => {
  const section = props.section
  const type = props.type

  // console.log(section)

  switch (type) {
    case "STRAPI_ComponentSectionsBanner":
      return <Banner section={section} />
    case "STRAPI_ComponentSectionsTextImpact":
      return <TextImpact section={section} />
    case "STRAPI_ComponentSectionsInBetween":
      return <InBetween section={section} />
    case "STRAPI_ComponentSectionsText":
      return <Text section={section} />
    case "STRAPI_ComponentSectionsBubbles":
      return <Bubbles section={section} />
    case "STRAPI_ComponentSectionsColumns":
      return <Columns section={section} />
    case "STRAPI_ComponentSectionsRectangles":
      return <Rectangles section={section} />
    case "STRAPI_ComponentSectionsSteps":
      return <Steps section={section} />
    case "STRAPI_ComponentSectionsParagraphs":
      return <Paragraphs section={section} />
    case "STRAPI_ComponentSectionsTitle":
      return <Title section={section} />
    case "STRAPI_ComponentSectionsSubtitle":
      return <Subtitle section={section} />
    case "STRAPI_ComponentSectionsReferences":
      return <References section={section} />
    case "STRAPI_ComponentSectionsPresentation":
      return <Presentation section={section} />
    case "STRAPI_ComponentSectionsHook":
      return <Hook section={section} />
    case "STRAPI_ComponentSectionsOffers":
      return <Offers section={section} />
    case "STRAPI_ComponentSectionsMarkdownBlocks":
      return <MarkdownBlocks section={section} />
    default:
      return null
  }
}

const WrapperSection = (props: any) => {
  const section = props.section
  const anchor_id = section.anchor_id
  const type = section.__typename

  const name = type.replace("STRAPI_ComponentSections", "").toLowerCase()

  return (
    <div name-attr={name} className="d-inline-flex">
      <div id={anchor_id} className="anchor" />
      <SwitchSection section={section} type={type}></SwitchSection>
    </div>
  )
}

const GroupedSection = (props: any) => {
  const sectionGroup = props.sectionGroup
  const index = props.index
  const backgroundChange = props.backgroundChange

  const onChangeInView = (
    inView: boolean,
    entry: IntersectionObserverEntry
  ) => {
    if (!inView) {
      return
    }

    let backgroundElements = entry.target.children
      .item(entry.target.children.length - 1)
      ?.getElementsByClassName("background")
    let lastItem = backgroundElements?.item(backgroundElements?.length - 1)
    let style = lastItem?.getAttribute("style") ?? ""
    if (style === undefined) {
      return
    }
    let match = new RegExp("(background|background-color): (.*?);").exec(style)

    if (match?.at(2) === undefined) {
      return
    }

    let value = match?.at(2)

    backgroundChange(value ?? "#ffffff")
  }

  const [ref, inView] = useInView({
    threshold: 0.05,
    triggerOnce: true,
    onChange: onChangeInView,
  })

  let opacity = 500
  let transform = 500

  return (
    <div
      ref={ref}
      className="flex-fill"
      style={{
        transition: `opacity ${opacity}ms, transform ${transform}ms`,
        opacity: inView ? 1 : 0,
        transform:
          index == 0
            ? `scale(${inView ? 1 : 1.25})`
            : `translateY(${inView ? 0 : 75}px)`,
        display: "inline-flex",
        zIndex: 1000 - index,
      }}
    >
      <div className="d-flex flex-column w-100">
        {sectionGroup.map((section: any, index: number) => (
          <WrapperSection key={index} section={section} />
        ))}
      </div>
    </div>
  )
}

export default GroupedSection
