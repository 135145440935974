import * as React from "react"
import "./Banner.scss"
import FullLogo from "../../assets/images/oiseau_blanc.png"
import BannerBackground from "../../assets/images/banner.svg"

type BannerProps = {
  text: string
}

const Banner = (props: any) => {
  const section: BannerProps = props.section

  const text = section.text

  return (
    <div className="w-100 mx-auto banner bg-primary background" style={{backgroundImage: `url( '${BannerBackground}' )`, backgroundPositionX: 'center' }}>
      <div className="container d-block position-relative text-center mb-5 pt-5">
        <img src={FullLogo} alt="Big Logo" style={{ height: "100px" }} className="mb-3" />
        <span
          className="d-block"
          style={{
            fontSize: "28px",
            fontWeight: "800",
            lineHeight: "36px",
            letterSpacing: "-0.015em",
            textAlign: "center",
            color: "#fff",
            maxWidth: "400px",
            margin: "auto",
          }}
        >
          {text}
        </span>
        <a 
          className="button-special"
          aria-label="1er rdv gratuit" 
          href="/#me-contacter" 
          style={{ 
            textDecoration: 'none', 
            display: 'inline-block', 
            marginTop: '2rem', 
            fontWeight: '700'
          }}>1ER RDV GRATUIT</a>
      </div>
    </div>
  )
}

export default Banner
