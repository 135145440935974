import * as React from "react"
import Helper from "../Helper/Helper"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import "./TextImpact.scss"

type TextImpactProps = {
  main: string
  ti_text_color: string
  ti_background_color: string
  shape: {
    data: {
      attributes: {
        url: string
        alternativeText: string
      }
    }
  }
  descriptions: {
    title: string
    subtitle: string
    title_text_color: string
    subtitle_text_color: string
  }[]
}

interface WrapperProps {
  children?: React.ReactNode
  shape: string
  shapeAlt: string
  background: string
}

const Wrapper = ({ children, shape, shapeAlt, background }: WrapperProps) => {
  const backgroundColor = background
    ? Helper.color(background)
    : "rgba(255,255,255,0.0)"

  return shape ? (
    <div
      className="w-100 py-3 background"
      title={shapeAlt}
      style={{
        backgroundImage: `url( '${shape}' )`,
        backgroundPosition: "right top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundColor: backgroundColor,
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <div className="container container-fluid text-impact">
        <div className="d-flex flex-wrap justify-content-center pt-6 mt-6">
          {children}
        </div>
      </div>
    </div>
  ) : (
    <div
      className="text-impact background w-100"
      style={{ background: backgroundColor }}
    >
      <div className="container container-fluid py-3 ">
        <div className="d-flex flex-wrap justify-content-center">
          {children}
        </div>
      </div>
    </div>
  )
}

const TextImpact = (props: any) => {
  const section: TextImpactProps = props.section

  const main = section.main
  const text_color = section.ti_text_color
  const background_color = section.ti_background_color
  const shape = section.shape?.data?.attributes?.url
  const shapeAlt = section.shape?.data?.attributes?.alternativeText
  const descriptions = section.descriptions

  return (
    <Wrapper shape={shape} shapeAlt={shapeAlt} background={background_color}>
      <div className="col-lg-6 col-12 px-4">
        <div
          className={`d-block text-${text_color ?? "black"} position-relative`}
          style={{
            fontSize: "60px",
            fontWeight: "700",
            lineHeight: "100px",
            letterSpacing: "-0.015em",
            textAlign: "start",
            zIndex: 1000,
          }}
        >
          <Markdown children={main} remarkPlugins={[remarkGfm]} />
        </div>
      </div>
      <div className="col-lg-6 col-12 px-4 mt-3">
        {descriptions.map(
          (
            description: {
              title: string
              subtitle: string
              title_text_color: string
              subtitle_text_color: string
            },
            index: number
          ) => (
            <div key={index} className={`mt-3 mb-5`}>
              <span
                className="d-block position-relative"
                style={{
                  fontSize: "27px",
                  fontWeight: "700",
                  lineHeight: "27px",
                  letterSpacing: "-0.015em",
                  textAlign: "start",
                  zIndex: 1000,
                  color: Helper.color(description.title_text_color),
                }}
              >
                {description.title}
              </span>
              <span
                className="d-block position-relative"
                style={{
                  fontSize: "27px",
                  fontWeight: "400",
                  lineHeight: "27px",
                  letterSpacing: "-0.015em",
                  textAlign: "start",
                  zIndex: 1000,
                  color: Helper.color(description.subtitle_text_color),
                }}
              >
                {description.subtitle}
              </span>
            </div>
          )
        )}
      </div>
    </Wrapper>
  )
}

export default TextImpact
